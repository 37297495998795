/* ref: https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.css */
.animated {
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 0.25s;
  --animate-repeat: 1;
}

.animated.animate__delay-1 {
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}
.animated.animate__delay-2 {
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

@keyframes expandIn {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 1000px;
    opacity: 1;
  }
}

.expandIn {
  -webkit-animation-name: expandIn;
  animation-name: expandIn;
  overflow: hidden;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  /* transition: max-height 0.3s ease-out; */
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15%, 0);
    transform: translate3d(0, 15%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
