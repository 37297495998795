/* custom reset styles */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

html {
  height: 100%;
  /* word-break: break-word; */
}
pre {
  white-space: pre-wrap;
  margin: 0;
}
.whitespace-pre {
  white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 13px;
  }
}

body {
  height: 100%;
  margin: 0;
  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  ); */
}

@media only screen and (max-width: 992px) {
  html {
    font-size: var(--font-size-mobile) !important;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#root a {
  text-decoration: none !important;
}
a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
}
#root a:visited {
  color: inherit;
}

/* main {
  background: white;
} */

/* --- ScrollBar --- */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.05); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* --- Color Input --- */
input[type="color"] {
	-webkit-appearance: none;
	height: 32px;
  border: 1px solid white;
  border-radius: 2px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

/* --- Mui Theme --- */
.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
  border: none;
  content: none;
}
.MuiFilledInput-underline {
  background-color: transparent;
  border-radius: var(--border-radius);
}
.MuiFilledInput-underline:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05)
}
.MuiFilledInput-input {
  cursor: pointer;
}
.MuiFilledInput-underline.MuiAutocomplete-inputRoot {
  padding: 0;
}
.MuiChip-root {
  height: 24px;
  border-radius: 4px
}
.MuiTabs-root .Mui-selected {
  color: inherit;
}
.MuiTimelineItem-root:before {
  flex: 0;
  padding: 0;
}

.MuiInputAdornment-positionStart {
  margin-top: 0 !important;
  opacity: 0.5;
}
.MuiButton-root {
  min-width: 24px;
}
.MuiDivider-root {
  border-color: var(--tint-border)
}

/* You can't change the background color of autofill, we can only cover it */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

input {
  padding: 12px !important;
  border: none;
  font-size: 16px;
  line-height: 24px !important;
  font: inherit;
  color: rgba(0,0,0,0.87);
}
input::placeholder, textarea::placeholder {
  color: #999;
  color: var(--gray);
}

fieldset {
  border: none;
  border-radius: 0;
}

*:focus-visible {
  outline: 0;
}


.slate-BalloonToolbar, .slate-Combobox {
  z-index: 9000 !important
}
[data-slate-editor] {
  padding: 0 !important;
}

/* --- Utils --- */
.uppercase {
  text-transform: uppercase;
}
.text-secondary {
  color: var(--gray-text)
}
[data-line-clamp] {
  -webkit-line-clamp: 1; /* attr(data-line-clamp) */
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
}
[data-line-clamp][data-clamp-2] {
  -webkit-line-clamp: 2; /* attr(data-line-clamp) */
}
[data-line-clamp][data-clamp-3] {
  -webkit-line-clamp: 3;
}
.nowrap {
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.display-block {
  display: block;
}
.hidden {
  display: none;
}
.align-middle {
  vertical-align: middle;
}
.tint-white {
  background-color: var(--tint-white) !important;
}
.full-width {
  width: 100%;
}
.max-full-width {
  max-width: 100%;
}
.hoverable {
  transition: transform 0.15s;
}
.hoverable:hover {
  cursor: pointer;
  transform: translate(0, -3px);
  /* box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.3); */
}
.position-relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.hover-highlight:hover {
  background-color: rgba(255, 255, 255, 0.07);
}
.resize-vertical {
  resize: vertical;
}
.clickable {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: rgba(255, 255, 255, 0.1);
} 

textarea {
  font-size: 0.875rem; /* 14px */
  line-height: 1.125rem;
}
.shadow-sm {
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.5);
}
.text-primary {
  color: var(--primary);
}


/* --- Dark --- */
body.dark input:-webkit-autofill, body.dark input:-webkit-autofill:hover, body.dark input:-webkit-autofill:focus {
  box-shadow:  0 0 0 100px #266798 inset;
}
body.dark input {
  background-color: transparent
}
body.dark input, body.dark textarea {
  background-color: transparent
}
body.dark textarea, body.dark input {
  caret-color: white;
  color: inherit;
}


/* --- Light --- */
.light input:-webkit-autofill, .light input:-webkit-autofill:hover, .light input:-webkit-autofill:focus {
  box-shadow:  0 0 0 100px #fff inset !important;
}
.light input {
  background-color: transparent !important
}
.light input, .light textarea {
  background-color: transparent !important 
}
.light textarea, .light input {
  caret-color: black !important;
  color: black !important;
}



/* --- Markdown --- */
.markdown table {
  text-align: left;
  border-collapse: collapse;
}
.markdown th, .markdown td {
  border-bottom: 1px solid;
  padding-left: var(--spacing);
  padding-top: var(--spacing);
  padding-bottom: var(--spacing);
}

/* Temp */

.react-flow__attribution {
  display: none;
}

.blob {
  display: inline-block;
  vertical-align: middle;
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	margin: 10px;
	height: 10px;
	width: 10px;
	transform: scale(1);
}

.blob {
	background: white;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

/* --- Utils --- */
.rounded {
  border-radius: 0.25rem; /* 4px */
}
.p-1 {
  padding: 0.25rem; /* 4px */
}
.p-2 {
  padding: 0.5rem; /* 8px */
}
.p-3 {
  padding: 0.75rem; /* 12px */
}
.p-4 {
  padding: 1rem; /* 16px */
}
[data-placeholder]::before {
  content: attr(data-placeholder);
  color: rgba(138,145,158, 0.75);
  position: absolute;
  top: 0;
  left: 0
}
.hover-underline:hover {
  text-decoration: underline;
}


/* --- LazyLoad --- */
.LazyLoad.is-visible {
  height: auto !important
}