.gradient {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      135deg,
      rgba(244, 42, 139, 0.3),
      rgba(31, 137, 219, 0.3),
      transparent
    ),
    linear-gradient(135deg, transparent, black),
    url(https://grainy-gradients.vercel.app/noise.svg);
  /* background: radial-gradient(
      135deg,
      rgba(244, 42, 139, 0.2),
      rgba(31, 137, 219, 0.2),
      transparent
    ),
    linear-gradient(135deg, transparent, black),
    url(https://grainy-gradients.vercel.app/noise.svg); */

  z-index: 0;
  opacity: 0.7;
}

.content {
  z-index: 1;
}

.features {
  flex-wrap: wrap;
}

.featureBox {
  flex-basis: 50%;
}
.featureCard {
  margin-right: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
  padding: var(--spacing-xxl);
  height: 450px;
  /* background-position: bottom right;
  background-size: 350px;
  background-repeat: no-repeat; */
  position: relative;
  overflow: hidden;
}
.featureBox:nth-child(2n) {
  position: relative;
  top: 150px;
}
.featureDescription {
  max-width: 420px;
}

.featureImg {
  position: absolute;
  bottom: -120px;
  right: 0;
  height: 370px;
  width: 370px;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 400px;
  transform: rotateX(50deg) rotateY(350deg) rotateZ(45deg);
  box-shadow: rgb(0, 0, 0) 15px 10px 25px -5px;
  border-radius: var(--border-radius);
}

.featureTitle {
  font-size: var(--font-size-h4);
  letter-spacing: 1.5px;
  color: var(--gray-text);
  text-transform: uppercase;
}
.sectionTitle {
  font-size: var(--font-size-h4);
  letter-spacing: 1px;
  font-weight: 400;
  /* color: var(--gray-text); */
  /* text-transform: uppercase; */
}
.featureTitle strong {
  color: white;
}

.featureDialog {
  padding: var(--spacing-xl);
  /* background: var(--gray-bg);   */
  position: relative;
}

.borderCorner {
  border: 1px solid;
  border-color: var(--primary);
  position: absolute;
  height: var(--spacing-xl);
  width: var(--spacing-xl);
}
.bottomRightCorner {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
}
.topLeftCorner {
  top: 0;
  left: 0;
  border-bottom: none;
  border-right: none;
}
.topRightCorner {
  top: 0;
  right: 0;
  border-bottom: none;
  border-left: none;
}
.bottomLeftCorner {
  bottom: 0;
  left: 0;
  border-top: none;
  border-right: none;
}
.featureDiagram {
  min-height: 560px;
  height: 40vh;
}
.featureDiagram > div {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 33.333333%;
}

.orb {
  position: absolute;
  top: -100px;
  left: -100px;
  right: -100px;
  max-width: 150%;
}
